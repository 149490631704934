import Vue from 'vue'
import i18n from '@/i18n/index.js';
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LegalAdvice from '@/views/pages/legal-advice/LegalAdvice.vue'
import Cookies from '@/views/pages/cookies/Cookies.vue'
import Gallery from '@/views/pages/gallery/Gallery.vue'
import NotFound from '@/views/pages/404/NotFound.vue'
import OfferPage from '@/views/pages/offer/Offer.vue'
import FeedPage from '@/views/pages/feed/FeedView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: i18n.t('meta.home.title'), 
      description: i18n.t('meta.home.description')
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    meta: { 
      title: i18n.t('meta.gallery.title'), 
      description: i18n.t('meta.gallery.description')
    },
    component: Gallery
  },
  {
    path: '/legal-advice',
    name: 'legal-advice',
    meta: { title: i18n.t('meta.legal-advice.title') },
    component: LegalAdvice
  },
  {
    path: '/cookies',
    name: 'cookies',
    meta: { title: i18n.t('meta.cookies.title') },
    component: Cookies
  },
  {
    path: '/offer',
    name: 'offer-info',
    meta: {title: 'Nuestros productos para San Valentín!'},
    component: OfferPage
  },
  {
    path: '/feed',
    name: 'feed',
    meta: {title: 'Nuestros piensos'},
    component: FeedPage
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
