<template>
    <section class="section" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="left-text-content">
                        <div class="section-heading">
                            <h6>{{ $t('components.specialists.title-1') }}</h6>
                            <h2>{{ $t('components.specialists.title-2') }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/macetas.webp" :alt="$t('components.specialists.list.1')">
                                    <h4>{{ $t('components.products.list.1') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/pets.webp" :alt="$t('components.specialists.list.2')">
                                    <h4>{{ $t('components.products.list.2') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/complementos.webp" :alt="$t('components.specialists.list.3')">
                                    <h4>{{ $t('components.products.list.3') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/pool.webp" :alt="$t('components.specialists.list.4')">
                                    <h4>{{ $t('components.products.list.4') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/fito-sanitarios.webp" :alt="$t('components.specialists.list.4')">
                                    <h4>{{ $t('components.products.list.5') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/house-plants.webp" :alt="$t('components.specialists.list.4')">
                                    <h4>{{ $t('components.products.list.6') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="service-item">
                                    <img src="assets/images/Specialists/trees.webp" :alt="$t('components.specialists.list.4')">
                                    <h4>{{ $t('components.products.list.7') }}</h4>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <a href="#testimonials" class="main-button-icon">
                                    {{ $t('components.specialists.button') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="right-text-content">
                        <p v-html="$t('components.specialists.text.p-1') " />
                        <div class="content-box">
                            <p>{{ $t('components.specialists.text.p-2') }}</p>
                            <ul class="about-us-list">
                                <li>
                                    <p>{{ $t('components.specialists.text.list.1') }}</p>
                                </li>
                                <li>
                                    <p v-html="$t('components.specialists.text.list.2')"/>
                                </li>
                                <li>
                                    <p>{{ $t('components.specialists.text.list.3') }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Specialists'
}
</script>

<style>

</style>