<template>
  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <h1>{{ $t('components.footer.links.1') }}</h1>
        <p><b>{{ $t('pages.legal-advice.section-1.title') }}</b></p>
        <p v-html="$t('pages.legal-advice.section-1.text')"></p>
        <ul>
            <li v-html="$t('pages.legal-advice.section-1.list.1')"></li>
            <li v-html="$t('pages.legal-advice.section-1.list.2')"></li>
            <li v-html="$t('pages.legal-advice.section-1.list.3')"></li>
            <li v-html="$t('pages.legal-advice.section-1.list.4')"></li>
            <li v-html="$t('pages.legal-advice.section-1.list.5')"></li>
        </ul>

        <p><b>{{ $t('pages.legal-advice.section-2.title') }}</b></p>
        <p v-html="$t('pages.legal-advice.section-2.text')"></p>

        <p><b>{{ $t('pages.legal-advice.section-3.title') }}</b></p>
        <p>{{ $t('pages.legal-advice.section-3.text') }}</p>

        <p><b>{{ $t('pages.legal-advice.section-4.title') }}</b></p>
        <p v-html="$t('pages.legal-advice.section-4.text')"></p>

        <p><b>{{ $t('pages.legal-advice.section-5.title') }}</b></p>
        <p v-html="$t('pages.legal-advice.section-5.text')"></p>

        <p><b>{{ $t('pages.legal-advice.section-6.title') }}</b></p>
        <p v-html="$t('pages.legal-advice.section-6.text')"></p>

        <p><b>{{ $t('pages.legal-advice.section-7.title') }}</b></p>
        <p>{{ $t('pages.legal-advice.section-7.text') }}</p>

        <p><b>{{ $t('pages.legal-advice.section-8.title') }}</b></p>
        <p>{{ $t('pages.legal-advice.section-8.text') }}</p>
        <ul>
            <li><b>{{ $t('pages.legal-advice.section-8.list.1') }}</b> Avinguda Llevant, s/n 07560 Cala Millor</li>
            <li><b>{{ $t('pages.legal-advice.section-8.list.2') }}</b> <a href='tel:678 945 417'>678 945 417</a></li>
            <li><b>{{ $t('pages.legal-advice.section-8.list.3') }}</b><a href='mailto:Magatzemvbgarden@gmail.com'> Magatzemvbgarden@gmail.com</a></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LegalAdvice",
};
</script>

<style scoped>
.section{
  margin: 80px 0;
  padding: 0 15px;
}
p{
  display: block;
  width: 100%;
  line-height: 40px;
}
</style>