<template>
  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <h1>{{ $t('components.footer.links.2') }}</h1>
        <h3>{{ $t('pages.cookies.section-1.title') }}</h3>
        <p>
          {{ $t('pages.cookies.section-1.text') }}
        </p>

        <h3>{{ $t('pages.cookies.section-2.title') }}</h3>
        <ul>
          <li v-html="$t('pages.cookies.section-2.text')"> </li>
        </ul>

        <h3>{{ $t('pages.cookies.section-3.title') }}</h3>
        <p>
          {{ $t('pages.cookies.section-3.text') }}
        </p>

        <h3>{{ $t('pages.cookies.section-4.title') }}</h3>
        <p>
          {{ $t('pages.cookies.section-4.text') }}
        </p>

        <h3>{{ $t('pages.cookies.section-5.title') }}</h3>
        <p>
          {{ $t('pages.cookies.section-5.text') }}
        </p>

        <h3>{{ $t('pages.cookies.section-6.title') }}</h3>
        <p>
          {{ $t('pages.cookies.section-6.text') }}
          <router-link :to="{ name: 'legal-advice' }">{{ $t('pages.cookies.section-6.link') }}</router-link>.
        </p>
      </div>
    </div>
  </section>
</template>
  
<script>
export default {
  name: "Cookies",
};
</script>
  
<style scoped>
.section{
  margin: 80px 0;
  padding: 0 15px;
}

h1{
  margin-bottom: 20px;
}

h3{
  display: block;
  width: 100%;
}
.section ul{
  list-style: initial;
  line-height: 30px;
  padding: 10px;
}
p{
  display: block;
  width: 100%;
  line-height: 40px;
}
</style>