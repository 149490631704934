<template>
  <section class="section container" id="projects">
    <div class="row">
        <div :class="(viewHome) ? 'col-lg-3' : 'col-lg-12'">
            <div class="section-heading">
            <h6>{{ $t('components.products.title-1') }}</h6>
            <h2>{{ (viewHome) ? $t('components.products.title-2') : '' }}</h2>
          </div>
          <div class="button-container">
            <button :data-type="(viewHome) ? '' : 'button-inline'" v-for="(category, index) in categories" :key="index" @click="showImages(category)" :class="{ active: category === activeCategory }">
               {{ $t(`components.products.list.${index + 1}`) }}
            </button>
          </div>
       </div>
       <div :class="(viewHome) ? 'col-lg-9' : 'col-lg-12'">
        <div v-if="loading"  class="loader-container">
          <loader />
        </div>
        <div v-else class="image-container">
          <img
            :class="(viewHome)? 'gallery-image gallery-home': 'gallery-image'"
            v-for="(image, index) in filteredImages"
            :src="image.src"
            :alt="image.alt"
            :key="index"
            loading="lazy"
            @click="openModal(image.src)"
          />
        </div>
       </div>
      <!-- Modal -->
      <transition name="modal-fade">
      <div class="modal" v-if="modalImage">
        <span class="close" @click="closeModal">&times;</span>
        <img class="modal-content" :src="modalImage" :alt="modalAlt" loading="lazy" />
      </div>
     </transition>
    </div>
  </section>
</template>

<script>
import { imagesHome, imagesGallery } from '@/service/products'
import { categoriesHome, categoriesGallery } from '@/service/categories'
import Loader from '../Loader.vue';

export default {
  name: 'Products',
  components: { Loader },
  data() {
    return {
      loading: true,
      images: [],
      categories: [],
      activeCategory: 'plantas',
      currentCategory: '',
      modalImage: '',
      modalAlt: '',
    };
  },
  props: {
    viewHome: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },
  computed: {
    filteredImages() {
      return this.images.filter(image => image.category === this.currentCategory || this.currentCategory === 'all');
    }
  },
  created() {
    this.currentCategory = 'plantas';
    this.getCategories();
    this.getImages();
  },
  methods: {
    hidingLoading(){
      // Contador para rastrear cuántas imágenes se han cargado
      let loadedImagesCount = 0;

      // Función para verificar si todas las imágenes se han cargado
      const checkAllImagesLoaded = () => {
        loadedImagesCount++;
        if (loadedImagesCount === this.images.length) {
          // Todas las imágenes se han cargado, establecer loading en false
          this.loading = false;
        }
      };

      // Asignar el evento load a cada imagen
      this.images.forEach(image => {
        const imgElement = new Image();
        imgElement.src = image.src;
        imgElement.addEventListener('load', checkAllImagesLoaded);
      });
    },
    getCategories(){
      this.categories = this.viewHome ? categoriesHome : categoriesGallery;
    },
    getImages(){
      this.images = this.viewHome ? imagesHome : imagesGallery;
      this.hidingLoading();
    },
    showImages(category) {
      this.activeCategory = category;
      this.currentCategory = category;
      this.loading = true;
      this.hidingLoading();
    },
    openModal(src) {
      this.modalImage = src;
      this.modalAlt = this.images.find(image => image.src === src).alt;
    },
    closeModal() {
      this.modalImage = '';
      this.modalAlt = '';
    }
  }
};
</script>

<style scoped>
[data-type="button-inline"] {
  display: inline-block;
  margin: 5px;
}
.loader-container{
  text-align: center;
  margin: 40px 0;
}
/* Estilos para la transición de entrada del modal */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}
/* Estilos para los botones */
.button-container {
  margin: 20px 0;
}

button {
  background-color: initial; 
  color: rgb(51, 155, 158);
  border: 2px solid;
  padding: 10px 13px;
  border-radius: 25px;
  cursor: pointer;
  margin: 5px 0px;
  transition: background-color 0.3s;
  font-size: 14px;
  display: block;
  font-weight: bold;
}

button.active{
  background-color: rgb(51, 155, 158);
  color: #fff;
}

button:hover {
  background-color: rgb(51, 155, 158);
  color: #fff;
}

/* Estilos para la galería */
.image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 20px;
}

.gallery-image.gallery-home {
  max-width: calc(14% - 10px);
}

.gallery-image {
  max-width: calc(10% - 10px);
  height: auto;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  object-fit: cover;
  height: 225px;
}

.gallery-image:hover {
  transform: scale(1.05);
}
.gallery-image:hover {
  transform: scale(1.05);
}

/* Estilos para el modal */
.modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 70%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  max-width: 80%;
  max-height: 90%;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 10px;
  right: 50px;
  color: #fff;
  font-size: 85px;
  cursor: pointer;
  width: unset;
  font-weight: normal;
}
@media (max-width: 767px) {
  .button-container button{
    padding: 10px 13px;
    margin: 3px;
    font-size: 10px;
    display: inline-block;
  }
  .gallery-image{
    max-width: calc(20% - 10px);
    height: auto;
  }
  #projects{
    padding-top: 50px;
  }
  .modal-content{
    max-width: 95%;
    max-height: 80%;
  }
  .close{
    top: 0;
    right: 10px;
    font-size: 58px;
  }
  .image-container{
    gap: 10px;
  }
  .image-container{
    margin-top: 10px;
  }
}
@media (max-width: 450px) {
  .gallery-image.gallery-home {
    max-width: calc(33% - 7px);
  }
}
</style>
