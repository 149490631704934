<template>
  <div>
    <banner />
    <specialists />
    <features />
    <feed />
    <div class="products">
      <products :viewHome="true" />
      <div class="button-container">
        <router-link :to="{ name: 'gallery' }">
          <button class="btn">{{ $t('components.buttons.see-more') }}</button>
        </router-link>
      </div>
    </div>
    <members />
    <phone />
    <contact-us></contact-us>
  </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
import Specialists from '@/components/Specialists/Specialists.vue'
import Features from '@/components/Features/Features.vue'
import Phone from '@/components/Phone/Phone.vue'
import Products from '@/components/Products/Products.vue'
import Members from '@/components/Members/Members.vue'
import ContactUs from '@/components/ContactUs/ContactUs.vue'
import Feed from '@/components/Feed/Feed.vue'
export default {
  name: 'HomeView',
  components: {Banner, Specialists, Features, Phone, Products, Members, ContactUs, Feed}
}
</script>
<style scoped>
.products .button-container{
  text-align: center;
}
</style>
