<template>
  <div class="carousel">
    <div class="carousel-inner">
      <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked" />
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/cartel.jpeg" alt="Cartél San Valentín"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-1.png" alt="Rosas 1"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-2.png" alt="Rosas 2"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-4" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-3.png" alt="Rosas 3"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-5" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/flores-frescas.png" alt="Flores frescas"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-6" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/flores-frescas-2.png" alt="Flores frescas 2"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-7" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/flores-frescas-3.png" alt="Flores frescas 3"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-8" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-4.png" alt="Rosas 4"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-9" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-5.png" alt="Rosas 5"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-10" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-6.png" alt="Rosas 6"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-11" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-7.png" alt="Rosas 7"/>
      </div>
      <input class="carousel-open" type="radio" id="carousel-12" name="carousel" aria-hidden="true" hidden=""/>
      <div class="carousel-item">
        <img src="../../assets/images/festivos/enamorados/rosas-8.png" alt="Rosas 8"/>
      </div>
      <label for="carousel-12" class="carousel-control prev control-1">‹</label>
      <label for="carousel-2" class="carousel-control next control-1">›</label>
      <label for="carousel-1" class="carousel-control prev control-2">‹</label>
      <label for="carousel-3" class="carousel-control next control-2">›</label>
      <label for="carousel-2" class="carousel-control prev control-3">‹</label>
      <label for="carousel-4" class="carousel-control next control-3">›</label>
      <label for="carousel-3" class="carousel-control prev control-4">‹</label>
      <label for="carousel-5" class="carousel-control next control-4">›</label>
      <label for="carousel-4" class="carousel-control prev control-5">‹</label>
      <label for="carousel-6" class="carousel-control next control-5">›</label>
      <label for="carousel-5" class="carousel-control prev control-6">‹</label>
      <label for="carousel-7" class="carousel-control next control-6">›</label>
      <label for="carousel-6" class="carousel-control prev control-7">‹</label>
      <label for="carousel-8" class="carousel-control next control-7">›</label>
      <label for="carousel-7" class="carousel-control prev control-8">‹</label>
      <label for="carousel-9" class="carousel-control next control-8">›</label>
      <label for="carousel-8" class="carousel-control prev control-9">‹</label>
      <label for="carousel-10" class="carousel-control next control-9">›</label>
      <label for="carousel-9" class="carousel-control prev control-10">‹</label>
      <label for="carousel-11" class="carousel-control next control-10">›</label>
      <label for="carousel-10" class="carousel-control prev control-11">‹</label>
      <label for="carousel-12" class="carousel-control next control-11">›</label>
      <label for="carousel-11" class="carousel-control prev control-12">‹</label>
      <label for="carousel-1" class="carousel-control next control-12">›</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      currentIndex: 0, // Índice actual del carrusel
      intervalId: null, // ID del intervalo para el carrusel automático
      intervalDuration: 4000 // Duración en milisegundos entre cambios de imagen (3 segundos en este caso)
    };
  },
  methods: {
    startCarousel() {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, this.intervalDuration);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % 6; // Cambiar 5 al número total de imágenes
      this.changeSlide(this.currentIndex);
    },
    changeSlide(index) {
      const radios = document.getElementsByName('carousel');
      radios[index].checked = true;
    }
  },
  mounted() {
    // this.startCarousel(); // Comienza el carrusel automáticamente al montar el componente
  },
  beforeDestroy() {
    clearInterval(this.intervalId); // Limpia el intervalo cuando el componente se destruye para evitar pérdidas de memoria
  }
};
</script>

<style scoped>
.carousel {
  position: relative;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
  margin-top: 26px;
  margin-bottom: 42px;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
  display: block;
}

.carousel-item img {
    max-width: 100%;
    height: 800px;
    width: 100%;
    object-fit: cover;
}

.carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 40px;
  height: 40px;
  line-height: 35px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  cursor: pointer;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width: 40px;
  z-index: 10;
}

.carousel-control.prev {
  left: 2%;
}

.carousel-control.next {
  right: 2%;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3,
#carousel-4:checked ~ .control-4,
#carousel-5:checked ~ .control-5,
#carousel-6:checked ~ .control-6,
#carousel-7:checked ~ .control-7,
#carousel-8:checked ~ .control-8 ,
#carousel-9:checked ~ .control-9, 
#carousel-10:checked ~ .control-10,
#carousel-11:checked ~ .control-11,
#carousel-12:checked ~ .control-12{
  display: block;
}

.carousel-bullet {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 35px;
}

@media screen and (max-width: 999px) {
    .carousel-item img{
        height: 250px;
    }
    #features{
        padding-bottom: 0;
    }
}
</style>