<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
    <Dialog />
  </div>
</template>

<script>
import Header from './components/Header/TheHeader.vue';
import Footer from './components/Footer/TheFooter.vue';
import Dialog from './components/Dialog/Dialog.vue';

export default {
  name: 'App',
  components: { Header, Footer, Dialog },
  data() {
      return {}
  },
  created(){
    this.setMetaDescription(this.$route);
  },
  watch: {
    $route(to) {
      this.setMetaDescription(to);
    }
  },
  methods: {
    setMetaDescription(route) {
      const description = route.meta.description || '';
      document.querySelector('meta[name="description"]').setAttribute('content', description);
      document.title = route.meta.title ? `${route.meta.title} - Magatzem VB Garden` : "Magatzem VB Garden";
    }
  }
}

</script>

<style lang="scss">
@import "assets/scss/style";
.btn{
  margin-top: 30px;
  font-size: 13px;
  border-radius: 25px;
  padding: 13px 25px;
  background-color: #5fb759;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767px) {
  .btn{
    font-size: 14px;
    padding: 5px 25px;
  }
}
</style>
