export const categoriesHome = [
    'plantas',
    'planter',
    'fodder',
    'animal'
];
export const categoriesGallery = [
    'plantas',
    'planter',
    'fodder',
    'animal',
    'garden',
    'pool-articles',
    'products-fitos',
    'pot-decoration',
    'fruit-and-ornamental',
    'all',
];