<template>
    <section class="section" id="features">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    <div class="features-item">
                        <div class="features-icon">
                            <img src="assets/images/Features/feature-1.webp" :alt="$t('components.features.list.1.title')">
                        </div>
                        <div class="features-content">
                            <h4>{{ $t('components.features.list.1.title') }}</h4>
                            <p>{{ $t('components.features.list.1.text') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s">
                    <div class="features-item">
                        <div class="features-icon">
                            <img src="assets/images/Features/feature-2.webp" :alt="$t('components.features.list.2.title')">
                        </div>
                        <div class="features-content">
                            <h4>{{ $t('components.features.list.2.title') }}</h4>
                            <p>{{ $t('components.features.list.2.text') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                    <div class="features-item">
                        <div class="features-icon">
                            <img src="assets/images/Features/feature-3.webp" :alt="$t('components.features.list.3.title')">
                        </div>
                        <div class="features-content">
                            <h4>{{ $t('components.features.list.3.title') }}</h4>
                            <p>{{ $t('components.features.list.3.text') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
   name: 'Features'
}
</script>

<style scope>
.features-icon img{
    height: 50px;
}

</style>