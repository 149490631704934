<template>
  <section class="section" id="features">
    <div class="container">
        <div class="row">
            <h1>{{ $t('pages.gallery') }}</h1>
            <Products :viewHome="false"/>
        </div>
    </div>
  </section>
</template>

<script>
import Products from '@/components/Products/Products.vue'
export default {
  name: 'Gallery',
  components: {Products}
}
</script>

<style scoped>
#features{
    padding: 120px 15px
}
.section{
    padding: 0px 0 40px 0;
    position: relative;
}
.section h1{
    position: absolute;
    top: 160px;
}
@media (max-width: 767px) {
    .section h1{
        top: 112px;
    }
}
</style>