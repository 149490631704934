import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en.json'
import de from './de.json'
import sp from './sp.json'

Vue.use(VueI18n);

// Cargar las traducciones
const messages = {
  en: en,
  sp: sp,
  de: de
}

// Crear la instancia de VueI18n
const i18n = new VueI18n({
  legacy: false, // Necesario para Vue 3
  locale: 'sp', // Idioma por defecto
  fallbackLocale: 'sp', // En caso de que no se encuentre la traducción en el idioma actual
  messages, // Mensajes de traducción
});

export default i18n;