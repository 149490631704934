<template>
   <footer>
    <cookies />
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-xs-12">
                    <div class="left-text-content">
                        <p>Avinguda Llevant, s/n 07560 Cala Millor</p>
                        <p><a href='mailto:Magatzemvbgarden@gmail.com'>Magatzemvbgarden@gmail.com</a></p>
                        <p>Copyright &copy; 2023. Magatzem VB Garden</p>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12 footer-logo">
                    <img src="assets/images/logo.webp" alt="Magatzem VB Garden Footer">
                </div>
                <div class="col-lg-4 col-xs-12">
                    <div class="right-text-content">
                            <ul class="footer-pages">
                                <li><router-link :to="{ name: 'legal-advice' }">{{ $t('components.footer.links.1') }}</router-link></li>
                                <li><router-link :to="{ name: 'cookies' }">{{ $t('components.footer.links.2') }}</router-link></li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import Cookies from '@/components/Cookies/Cookies.vue'
export default {
  name: 'TheFooter',
  components: {Cookies}
}
</script>

<style scoped>
.footer-logo img{
    width: 100%;
    height: 60px;
    object-fit: contain;
}

.footer-pages li{
    display: block;
}

.footer-pages li a{
    color: #fff;
    font-size: 14px;
}
@media (max-width: 767px) {
    .footer-logo{
        margin: 15px 0;
    }
}
</style>