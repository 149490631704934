<template>
  <section class="section" id="features">
    <div class="container">
      <h1>{{ $t('pages.offer.title') }}</h1>
      <div class="row row-container">
        <div class="flower">
          <Carousel />
          <h2>{{ $t('pages.offer.items.0.title') }}</h2>
          <p>
            {{ $t('pages.offer.items.0.text') }}
          </p>
          <h2>{{ $t('pages.offer.items.1.title') }}</h2>
          <p>
            {{ $t('pages.offer.items.1.text') }}
          </p>
          <h2>{{ $t('pages.offer.items.2.title') }}</h2>
          <p>
            {{ $t('pages.offer.items.2.text') }}
          </p>
          <h2>{{ $t('pages.offer.items.3.title') }}</h2>
          <p>
            {{ $t('pages.offer.items.3.text') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import Carousel from '@/components/Carousel/Carousel.vue';
export default {
  name: "OfferPage",
  components: {Carousel}
};
</script>
  
<style scoped>
h1 {
  display: block;
  width: 100%;
}
.row-container {
  display: grid;
}
.section {
  margin: 80px 0;
  padding: 0 15px;
}
.flower {
  margin-bottom: 40px;
  width: 100%;
  text-align: left;
}
.flower img {
  width: 100%;
  height: 600px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  object-fit: cover;
}
.flower h2 {
  margin-top: 15px;
  color: #2c3e50;
}
span {
  font-size: 20px;
}
p {
  color: #333;
  line-height: 32px;
  width: 100%;
  line-height: 32px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .flower img {
    height: 200px;
  }
  .row-container {
    display: block;
  }
  span{
    font-size: 16px;
  }
}
</style>