<template>
  <header class="header-area header-sticky">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav class="main-nav">
                        <!-- ***** Logo Start ***** -->
                        <a href="/" class="logo">
                            <img src="assets/images/logo.webp" alt="Magatzem VB Garden Logo">
                        </a>
                        <!-- ***** Logo End ***** -->
                        <!-- ***** Menu Start ***** -->
                        <ul class="nav">
                            <li class="scroll-to-section" v-for="(item, index) in mainMenu" :key="index">
                                <router-link :to="item.href">
                                    {{ item.text }}
                                </router-link>
                            </li> 
                        </ul>        
                        <a class='menu-trigger'>
                            <span>Menu</span>
                        </a>
                        <!-- ***** Menu End ***** -->
                    </nav>
                    <div class="language-switcher">
                        <LanguageSwitcher />
                    </div>
                    <SocialIcons />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';
import SocialIcons from './SocialIcons.vue';
export default {
   name: 'TheHeader',
   components: { LanguageSwitcher, SocialIcons },
   data(){
    return{
      currentPage: ''
    }
   },
   created(){
      this.currentPage = this.$route.name
   },
   watch: {
    $route() {
      this.currentPage = this.$route.name
    }
  },
   computed: {
     mainMenu(){
        return[
          {text: this.$t('components.menu.home'), href:`${(this.currentPage === 'home' ? '#top' : '/#top')}`},
          {text: this.$t('components.menu.about-us'), href:`${(this.currentPage === 'home' ? '#about' : '/#about')}`},
          {text: this.$t('components.menu.products'), href:`${(this.currentPage === 'home' ? '#projects' : '/#projects')}`},
        //   {text: this.$t('pages.offer.menu.title'), href:`/offer`},
          {text: this.$t('components.menu.contact-us'), href:`${(this.currentPage === 'home' ? '#contact-us' : '/#contact-us')}`}
        ]
     }
    }
}
</script>

<style scoped>
.language-switcher{
    position: absolute;
    right: 100px;
    top: 23px;
    z-index: 4;
}
.logo img{
    height: 70px;
    object-fit: contain;
}

@media (max-width: 1151px) {
    .header-area .main-nav{
        padding-right: 0 !important;
    }
    .language-switcher{
        right: 0;
        top: 82px;
        z-index: 4;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.5607843137);
    }
}
@media (max-width: 767px) {
    .header-area .logo {
        margin-left: 0;
    }
    .header-area .logo img{
        width: 145px;
    }
    .language-switcher{
        padding: 5px;
    }
}
</style>