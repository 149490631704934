<template>
    <section class="cookies" v-if="!cookie">
        <div class="cookies-container">
            <div class="cookies-row row">
                <div class="col-lg-8 cookies-col">
                    <div class="cookies-content">
                          <div class="cookies-header">
                              <!-- <v-icon class="cookies-icon">mdi mdi-cookie</v-icon> -->
                              <div class="cookies-title">{{ $t("components.cookie.cookie-title") }}</div>
                          </div>
                          <p class="cookies-text">
                              {{ $t("components.cookie.cookie-text") }}
                           </p>
                      </div>
                </div>
                <div class="col-lg-4">
                    <div class="cookies-col">
                        <div class="cookies-buttons">
                           <button  class="cookies-button" @click="acceptCookie">
                               {{ $t("components.cookie.yes") }}
                           </button>
                           <button class="cookies-button" @click="deniedCookie">
                               {{ $t("components.cookie.no") }}
                           </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
</template>
  
<script>
  export default {
    name: 'Cookies',
    data: () => ({
      cookie: true,
    }),
    created() {
          if (this.$cookies.isKey("lang") == false) {
             localStorage.setItem("accept_cookie", false);
             localStorage.setItem("cookie", false);
          }
          if (localStorage.getItem("accept_cookie") == null ||localStorage.getItem("accept_cookie") == "false") {
              if (localStorage.getItem("cookie") == null ||localStorage.getItem("cookie") == "false") this.cookie = false;
          }
      },
      methods: {
          acceptCookie() {
              this.$cookies.set( "lang", { lang: this.$i18n.locale, active: true }, "30d")
              this.cookie = true;
              localStorage.setItem("accept_cookie", true);
              localStorage.setItem("cookie", true);
              this.$i18n.locale = this.$cookies.get("lang").lang;
          },
          deniedCookie() {
              this.cookie = true;
              localStorage.setItem("accept_cookie", false);
              localStorage.setItem("cookie", true);
              this.$cookies.set("lang", { lang: null, active: false }, "1d");
          },
      }
  }
</script>
  
<style scoped>
 .cookies-row{
        max-width: 1500px;
        margin: 20px auto;
 }

  .cookies {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      font-family: Arial, sans-serif;
      z-index: 202;
  }
  
  .cookies-content {
      padding: 0.5rem;
  }
  
  .cookies-header {
      display: flex;
      align-items: left;
      margin-top: 10px;
  }
  
  .cookies-icon {
      color: white;
      font-size: 2rem;
      margin-right: 0.5rem;
  }
  
  .cookies-title {
      font-size: 1.5rem;
  }
  
  .cookies-text {
      font-size: 14px;
      color: #fff;
  }
  
  .cookies-link {
      color: white;
      text-decoration: underline;
  }
  
  .cookies-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
  }
  
  .cookies-button {
    margin: 1.2rem;
    transform: scale(1.2);
    background-color: rgb(51, 155, 158);
    border: 0;
    border-radius: 25px;
    color: #fff;
    min-width: 78px;
  }
  @media screen and (max-width: 960px) {
      .cookies .row {
          display: block;
          text-align: center;
          margin: 0 auto;
      }
  
      .cookies .row .col-md-3 {
          padding: 0;
      }
  
      .cookies_buttons .v-btn__content {
          font-size: 11px;
      }

      .cookies-buttons{
        margin-top: 0;
      }
  
      .cookies .cookies_button {
          height: 30px;
          min-width: auto;
          padding: 0px 11.111111px;
          width: 4rem;
      }

      .cookies_title {
          padding-top: 10px;
      }
  
      .cookies_buttons {
          padding-bottom: 10px;
      }

      .cookies-text{
        font-size: 14px;
      }
      .cookies-content{
        padding: 0;
      }
      .cookies-button {
        transform: initial;
      }
  }
</style>