<template>
   <section class="section" id="testimonials">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-heading">
                        <h6>{{ $t('components.our-team.title') }}</h6>
                        <h2>{{ $t('components.our-team.desc-1') }}</h2>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mobile-bottom-fix-big" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    <div class="owl-theme">
                        <div class="item author-item">
                            <div class="member-thumb">
                                <img src="assets/images/Members/team.webp" alt="">
                            </div>
                            <h4>{{ $t('components.our-team.desc-2') }}</h4>
                            <span>{{ $t('components.our-team.desc-3') }}</span>
                            <p class="message">
                                {{ $t('components.our-team.message') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Members'
}
</script>

<style scope>
#testimonials{
    margin-top: 120px;
}
.author-item .message{
    color: #fff;
    font-style: italic;
    padding: 10px 0;
    font-size: 16px;
    max-width: 420px;
    margin: 0 auto;
}
#testimonials .item .hover-effect ul li a:hover, a.main-button-icon, a.main-filled-button:hover, button.main-button:hover{
    padding-right: 25px !important;
}
@media (max-width: 767px) {
    .author-item .message{
        max-width: 100%;
        font-size: 13px;
    }
    #testimonials{
        margin-top: 50px;
        padding: 50px 0px;
        padding-bottom: 0px;
    }
}
</style>