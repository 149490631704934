<template>
  <div class="col-lg-12 col-md-12 col-xs-12 feed-container">
    <div class="col-lg-8 offset-lg-2">
      <h3>{{ $t('pages.feed.title') }}</h3>
      <span>
        {{ $t('pages.feed.short-desc') }}
      </span>
      <div class="button-container">
        <router-link :to="{ name: 'feed' }" class="feed-button">
          {{ $t("components.buttons.see-more") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feed",
};
</script>

<style scoped>
.feed-container {
  padding: 80px 0;
  background-color: #629582;
  color: #fff;
  text-align: center;
}
.feed-container h3 {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 0;
}

.feed-container span {
  margin: 10px 0;
  display: block;
}
.button-container {
  margin: 27px 0;
}
.feed-button {
  font-size: 15px;
  border-radius: 25px;
  padding: 13px 25px;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  border: 1px solid;
  transition: 0.3s;
  cursor: pointer;
  margin: 10px 5px;
  text-transform: uppercase;
}
</style>