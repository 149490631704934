<template>
  <div class="social-icons-container">
    <ul class="social-icons">
      <li>
        <a
          rel="nofollow"
          href="https://www.facebook.com/profile.php?id=61551126130887"
          target="_blank"
        >
          <img src="assets/images/facebook.png" alt="Facebook" />
        </a>
      </li>
      <li>
        <a
          rel="nofollow"
          href="https://www.instagram.com/magatzemvbgarden/"
          target="_blank"
        >
          <img
            class="instagram"
            src="assets/images/instagram.png"
            alt="Instagram"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    name: 'SocialIcons'
};
</script>

<style scope>
.social-icons-container{
    position: absolute;
    right: 0;
    top: 23px;
    z-index: 4;
}
.social-icons  li{
    display: inline-block;
    padding-right: 10px;
}
.social-icons img{
    height: 30px;
    border-radius: 5px;
}
@media (max-width: 1151px) {
  .social-icons-container{
    right: 50px;
  }
  .social-icons img{
    height: 25px;
    margin-top: 5px;
  }
}
</style>