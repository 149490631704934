<template>
  <section class="section">
    <div class="container">
        <div class="row">
            <h1>{{ $t('pages.feed.title') }}</h1>
            <span class="short-text">
                {{ $t('pages.feed.short-desc') }}
            </span>
            <div class="photos-container">
                <div>
                    <h4 class="photo-title">{{ $t('pages.feed.list.1.title') }}</h4>
                    <img src="assets/images/Products/fodder/pienso-1.webp" alt="Pienso 1">
                    <span>
                        {{ $t('pages.feed.list.1.text') }}
                    </span>
                </div>
            </div>
            <div class="photos-container">
                <h4>{{ $t('pages.feed.list.2.title') }}</h4>
                <div class="container-two">
                    <div>
                        <img src="assets/images/Products/fodder/pienso-2.webp" alt="Pienso 2">
                        <span>
                            {{ $t('pages.feed.list.3.text') }}
                        </span>
                    </div>
                    <div>
                        <img src="assets/images/Products/fodder/pienso-3.webp" alt="Pienso 3">
                        <span>
                            {{ $t('pages.feed.list.4.text') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeedView",
};
</script>

<style scoped>
.section {
  margin: 80px 0;
  padding: 0 15px;
}

.photo-title{
    margin-bottom: 30px;
}

.photos-container{
    margin: 30px 0;
}

.short-text{
    padding: 10px 0;
}

.container{
    padding: 40px 25px;
}

.photos-container img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin-bottom: 18px;
}

.container-two{
    display: flex;
    gap: 20px;
    margin: 30px 0 10px 0;
}
.container-two div{
    margin-bottom: 20px;
}
.container-two img{
    height: 800px;
}

h1 {
  margin-bottom: 20px;
}

h3 {
  display: block;
  width: 100%;
}
.section ul {
  list-style: initial;
  line-height: 30px;
  padding: 10px;
}
p {
  display: block;
  width: 100%;
  line-height: 40px;
}
@media (max-width: 1310px) {
    .container-two{
        gap: 0;
    }
}
@media (max-width: 1300px) {
    .container-two{
        display: block;
    }
    .container-two img{
        height: 400px;
    }
}
@media (max-width: 767px) {}
</style>