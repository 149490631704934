<template>
   <section class="section" id="subscribe">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="section-heading">
                        <h2>{{ $t('components.contact-us.text') }}</h2>
                    </div>
                    <div class="subscribe-content">
                        <span><a href="tel:678 945 417">678 945 417</a></span>
                        <span><a href='mailto:Magatzemvbgarden@gmail.com'>Magatzemvbgarden@gmail.com</a></span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Phone'
}
</script>

<style scoped>
#subscribe{
    padding-bottom: 100px;
}

@media (max-width: 767px) {
    #subscribe{
        padding: 40px 0;
    }
    #subscribe .subscribe-content{
        margin: 0px auto;
        display: block;
    }
    #subscribe .subscribe-content span{
        padding: 8px 11px;
        font-size: 13px;
        width: 100%;
        display: block;
        text-align: center;
        margin: 10px 0;
    }
    #subscribe .subscribe-content a{
        font-size: 11px;
    }
}

</style>