export const imagesHome = [
    { src: 'assets/images/Products/animal/animal-1.webp', alt: 'Producto animal 1', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-3.webp', alt: 'Producto animal 3', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-4.webp', alt: 'Producto animal 4', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-7.webp', alt: 'Producto animal 7', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-8.webp', alt: 'Producto animal 8', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-9.webp', alt: 'Producto animal 9', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-10.webp', alt: 'Producto animal 10', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-11.webp', alt: 'Producto animal 11', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-12.webp', alt: 'Producto animal 12', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-13.webp', alt: 'Producto animal 13', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-14.webp', alt: 'Producto animal 14', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-15.webp', alt: 'Producto animal 15', category: 'animal' },
    { src: 'assets/images/Products/plantas/plantas-1.webp', alt: 'Plantas 1', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-2.webp', alt: 'Plantas 2', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-21.webp', alt: 'Plantas 3', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-16.webp', alt: 'Plantas 16', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-5.webp', alt: 'Plantas 5', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-18.webp', alt: 'Plantas 6', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-9.webp', alt: 'Plantas 9', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-10.webp', alt: 'Plantas 10', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-11.webp', alt: 'Plantas 11', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-12.webp', alt: 'Plantas 12', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-13.webp', alt: 'Plantas 13', category: 'plantas' },
    { src: 'assets/images/Products/plantas/plantas-14.webp', alt: 'Plantas 14', category: 'plantas' },
    { src: 'assets/images/Products/planter/planter-1.webp', alt: 'Planter 1', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-2.webp', alt: 'Planter 2', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-3.webp', alt: 'Planter 3', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-4.webp', alt: 'Planter 4', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-5.webp', alt: 'Planter 5', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-6.webp', alt: 'Planter 6', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-7.webp', alt: 'Planter 7', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-8.webp', alt: 'Planter 8', category: 'planter' },
    { src: 'assets/images/Products/planter/planter-9.webp', alt: 'Planter 9', category: 'planter' },
    { src: 'assets/images/Products/fodder/fodder-1.webp', alt: 'Pienso 1', category: 'fodder' },
    { src: 'assets/images/Products/fodder/fodder-2.webp', alt: 'Pienso 2', category: 'fodder' },
    { src: 'assets/images/Products/fodder/fodder-3.webp', alt: 'Pienso 3', category: 'fodder' },
    { src: 'assets/images/Products/animal/animal-1.webp', alt: 'Producto animal 1', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-3.webp', alt: 'Producto animal 3', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-4.webp', alt: 'Producto animal 4', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-5.webp', alt: 'Producto animal 5', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-6.webp', alt: 'Producto animal 6', category: 'animal' },
    { src: 'assets/images/Products/animal/animal-23.webp', alt: 'Producto animal 23', category: 'animal' },
];
  

// Define una función para generar las imágenes
function generateImages(category, start, end) {
    const images = [];
    for (let i = start; i <= end; i++) {
        const src = `assets/images/Products/${category}/${category}-${i}.webp`;
        const alt = `Producto ${category} ${i}`;
        images.push({ src, alt, category });
    }
    return images;
}

// Define las categorías y el rango de imágenes para cada una
const categories = [
    { category: 'animal', start: 1, end: 23 },
    { category: 'plantas', start: 1, end: 61 },
    { category: 'garden', start: 1, end: 16 },
    { category: 'pool-articles', start: 1, end: 5 },
    { category: 'products-fitos', start: 1, end: 4 },
    { category: 'pot-decoration', start: 1, end: 21 },
    { category: 'fruit-and-ornamental', start: 1, end: 16 },
    { category: 'fodder', start: 1, end: 3 },
    { category: 'planter', start: 1, end: 10 },
    { category: 'all', start: 0, end: 0 }
];

// Genera las imágenes para cada categoría
const imagesGallery = [];
categories.forEach(({ category, start, end }) => {
    const images = generateImages(category, start, end);
    imagesGallery.push(...images);
});

// Exporta las imágenes
export { imagesGallery };