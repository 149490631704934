<template>
  <div>
    <div class="main-banner header-text" id="top">
        <div class="Modern-Slider">
          <div class="item">
            <div class="img-fill">
                <div class="overlay"></div> 
                <img src="assets/images/Slider/slider-01.webp" :alt="$t('components.banner.text-1.title')">
                <div class="text-content">
                  <h3>{{ $t('components.banner.text-1.title') }}</h3>
                  <h5>{{ $t('components.banner.text-1.desc') }}</h5>
                </div>
            </div>
          </div>
          <div class="item">
            <div class="img-fill">
                <div class="overlay"></div> 
                <img src="assets/images/Slider/slider-03.webp" :alt="$t('components.banner.text-2.title')">
                <div class="text-content">
                  <h3>{{ $t('components.banner.text-2.title') }}</h3>
                  <h5>{{ $t('components.banner.text-2.desc') }}</h5>
                </div>
            </div>
          </div>
          <div class="item">
            <div class="img-fill">
                <div class="overlay"></div> 
                <img src="assets/images/Slider/slider-04.webp" :alt="$t('components.banner.text-3.title')">
                <div class="text-content">
                  <h3>{{ $t('components.banner.text-3.title') }}</h3>
                  <h5>{{ $t('components.banner.text-3.desc') }}</h5>
                </div>
            </div>
          </div>
          <div class="item">
            <div class="img-fill">
                <div class="overlay"></div> 
                <img src="assets/images/Slider/slider-05.webp" :alt="$t('components.banner.text-4.title')">
            </div>
          </div>
          <div class="item">
            <div class="img-fill">
                <div class="overlay"></div> 
                <img class="img-sign" src="assets/images/Slider/slider-02.webp" alt="Magatzem VB Garden Entrada">
            </div>
          </div>
        </div>
    </div>
    <div class="scroll-down scroll-to-section">
      <a href="#about">
        <img src="assets/images/arrow-down.webp" alt="Scroll">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<style scoped>
.scroll-to-section img{
  height: 48px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .img-sign{
    width: 320px;
  }
}
</style>