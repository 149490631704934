<template>
     <section class="section" id="features">
        <div class="container">
            <div class="row not-found">
                <h1>{{ $t('pages.404.title') }}</h1>
                <p>{{ $t('pages.404.text-1') }}</p>
                <img src="/assets/images/plantita.gif" alt="Plantita" class="plantita-gif">
                <p>{{ $t('pages.404.text-2') }}</p>
                <ul>
                    <li>{{ $t('pages.404.list.1') }}</li>
                    <li v-html="$t('pages.404.list.2')"></li>
                </ul>
            </div>
        </div>
  </section>
</template>

<script>
export default {
name: 'NotFound'
}
</script>
  
<style scoped>
 .container h1, .container p{
    width: 100%;
    line-height: 50px;
 }
 .container{
    padding: 40px 0;
 }
 .not-found {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  display: block;
}

.not-found h1 {
  font-size: 2em;
}

.plantita-gif {
  max-width: 100%;
  margin: 20px;
  height: 400px;
}

.not-found ul {
  list-style-type: disc;
  padding-left: 20px;
}
ul li{
    font-size: 14px;
}
@media (max-width: 767px) {
    .container{
        padding: 5px 15px;
    }
    .plantita-gif{
        height: auto;
        object-fit: contain;
        width: 300px;
    }
    ul li{
        font-size: 14px;
    }
}
</style>