<template>
    <div>
        <button class="flag-btn" :class="{ 'flag-btn-selected': currentLang === 'sp' }" @click="changeLang ('sp')"><lang-flag iso="es" :squared="false"/></button>
        <button class="flag-btn" :class="{ 'flag-btn-selected': currentLang === 'en' }" @click="changeLang ('en')"><lang-flag iso="en" :squared="false"/></button>
        <button class="flag-btn" :class="{ 'flag-btn-selected': currentLang === 'de' }" @click="changeLang ('de')"><lang-flag iso="de" :squared="false"/></button>
    </div>    
</template>
    
<script>
    import LangFlag from 'vue-lang-code-flags';
    export default({
        name: 'LanguageSwitcher',
        components: {LangFlag},
        data: () => ({ 
            languages: ['en', 'sp', 'de'],
            currentLang: localStorage.getItem('lang') || 'sp',
        }),
        created(){
            this.$i18n.locale = localStorage.getItem('lang');
        },
        methods: {
            changeLang (lang) {
                localStorage.setItem('lang',lang);
                this.currentLang = lang;
                this.$i18n.locale = lang;
            }
        }
    })
</script>
    
<style scoped>
    .flag-btn {
        margin-right: 10px;
        opacity: .3;
    }
    .flag-btn-selected{
        opacity: 1;
    }
    @media (max-width: 767px) {
        .flag-btn {
            height: 24px;
            margin-right: 4px;
        }
        .flag-icon{
            height: 18px;
            line-height: 1.5em;
        }
    }
</style>