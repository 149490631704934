<template>
  <div v-if="showModal">
    <div id="overlay"></div>
    <div id="popup-offer">
      <div class="body-popup">
        <div class="info-popup">
          <h2 class="title">{{ $t('components.dialog.title') }}</h2>
          <p class="subtitle" v-html="$t('components.dialog.text')"></p>
          <p>
            <router-link :to="{ name: 'offer-info' }" @click.native="handleLinkClick">
              <button class="btn">{{ $t('components.buttons.see-more') }}</button>
            </router-link>
          </p>
        </div>
        <figure class="image">
          <img
            src="../../assets/images/festivos/enamorados/rosas.png"
            alt="Enamorados"
          />
        </figure>
        <span class="close" @click="closeModal">&times;</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    // Verificar si el modal debe estar oculto según localStorage
    const modalHidden = localStorage.getItem("modalOfferHidden");
    if (modalHidden) {
      this.showModal = false;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      localStorage.setItem("modalOfferHidden", "true");

      // Ocultar el modal durante 1 día
      setTimeout(() => {
        localStorage.removeItem("modalOfferHidden");
        this.showModal = true;
      }, 24 * 60 * 60 * 1000);
    },
    handleLinkClick() {
      this.closeModal();
    },
  }
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}
#popup-offer {
  display: block;
  position: fixed;
  background-color: #fff;
  border: 1px solid rgba(153, 153, 153, 0.212);
  left: 50%;
  top: 50%;
  padding: 0;
  max-height: 95vh;
  height: min-content;
  width: 1200px;
  transform: translate(-50%, -50%);
  overflow: auto;
  z-index: 201;
}
.body-popup .close {
  color: #000000;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
  z-index: 3;
  font-size: 67px;
  opacity: 1;
  line-height: 40px;
  background-color: #ffffffb8;
  font-weight: 400;
  height: 47px;
  width: 50px;
  padding-left: 10px;
}
#popup-offer .body-popup {
  display: flex;
  position: relative;
}
.body-popup .info-popup {
  flex-basis: 50%;
  padding: 65px;
}
.info-popup .title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 43px;
}
.info-popup .subtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  margin: 40px 0;
}
.body-popup .image {
  flex-basis: 50%;
  margin: 0;
}
.body-popup img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}
@media screen and (max-width: 999px) {
  #popup-offer {
    width: 95%;
  }
  #popup-offer .body-popup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column-reverse;
  }
  .body-popup img {
    height: 150px;
  }
  .info-popup .title {
    font-size: 15px;
  }
  .info-popup .subtitle {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  .body-popup .info-popup {
    padding: 20px;
  }
  .body-popup .close {
    font-size: 42px;
    width: 39px;
    height: 45px;
  }
}
</style>