<template>
      <section class="section" id="contact-us">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-xs-12">
                    <div class="left-text-content">
                        <div class="section-heading">
                            <h6>{{ $t('components.contact-us.title-1') }}</h6>
                            <h2>{{ $t('components.contact-us.title-2') }}</h2>
                        </div>
                        <ul class="contact-info">
                            <li><img src="assets/images/contact-info-01.webp" alt=""><a href="tel:678 945 417">678 945 417</a></li>
                            <li><img src="assets/images/contact-info-02.webp" alt=""><a href='mailto:Magatzemvbgarden@gmail.com'>Magatzemvbgarden@gmail.com</a></li>
                            <li><img src="assets/images/contact-info-03.webp" alt=""><span v-html="$t('components.contact-us.schedule')"></span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-xs-12">
                    <div class="contact-form">
                        <div style="width: 100%">
                            <iframe class="map" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Can%20Gost%C3%AD%20Garden%20Center%2007560,%20Balearic%20Islands+(Can%20Gost%C3%AD%20Garden%20Center)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
   name: 'ContactUs'
}
</script>

<style scoped>
.contact-info span{
    display: inline-grid;
}
@media (max-width: 767px) {
 #contact-us{
    padding: 40px 0px;
 }
 #contact-us .left-text-content ul li img{
    margin-right: 10px;
    width: 20px;
    height: 20px;
 }
 iframe{
    height: 300px;
 }
}
</style>